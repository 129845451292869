<template>
  <div>
    <div class="d-flex">
      <div class="w-25 div-center">
        <b-form-checkbox
          class="mr-2"
          id="checkbox-create"
          v-model="table_access.can_create"
          name="checkbox-create"
        >
          <h6>CREAR</h6>
        </b-form-checkbox>
      </div>
      <div class="w-100">
        <div class="d-flex">
          <!-- 1 -->
          <div v-if="show_can_read" class="w-100">
            <h6 class="text-center">VER</h6>
            <b-input-group class="mb-1">
              <b-input-group-append
                v-b-tooltip.v-secondary.auto.noninteractive="
                  `
                  ${
                    !checkbox_1
                      ? 'Seleccionar todos (incluyendo los que se creen posteriormente)'
                      : 'Deseleccionar todos'
                  }
                `
                "
              >
                <b-form-checkbox
                  class="mt-1"
                  id="checkbox-1"
                  v-model="checkbox_1"
                  @change="
                    () => {
                      if (checkbox_1) this.can_read = Items.map((x) => x.id);
                      else this.can_read = [];
                    }
                  "
                  name="checkbox-1"
                >
                </b-form-checkbox>
              </b-input-group-append>
              <b-form-input
                v-model="input_search1"
                type="search"
                size="sm"
                :disabled="checkbox_1"
                :placeholder="`Buscar ${$getVisibleNames(
                  Table.name,
                  false
                )}...`"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  :disabled="!input_search1"
                  @click="input_search1 = ''"
                  size="sm"
                  >Limpiar</b-button
                >
              </b-input-group-append>
            </b-input-group>
            <b-form-select
              :disabled="checkbox_1"
              :options="searchItems1"
              text-field="name"
              value-field="id"
              v-model="can_read"
              size="sm"
              :select-size="searchItems1.length < 7 ? searchItems1.length : 7"
              multiple
            ></b-form-select>
          </div>
          <!-- 2 -->
          <div class="ml-2 w-100">
            <h6 class="text-center">EDITAR</h6>
            <b-input-group class="mb-1">
              <b-input-group-append
                v-b-tooltip.v-secondary.auto.noninteractive="
                  `
                  ${!checkbox_2 ? 'Seleccionar Todos' : 'Deseleccionar Todos'}
                `
                "
              >
                <b-form-checkbox
                  class="mt-1"
                  id="checkbox-2"
                  v-model="checkbox_2"
                  @change="
                    () => {
                      if (checkbox_2) this.can_update = Items.map((x) => x.id);
                      else this.can_update = [];
                    }
                  "
                  name="checkbox-2"
                >
                </b-form-checkbox>
              </b-input-group-append>
              <b-form-input
                v-model="input_search2"
                type="search"
                size="sm"
                :disabled="checkbox_2"
                :placeholder="`Buscar ${$getVisibleNames(
                  Table.name,
                  false
                )}...`"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  :disabled="!input_search2"
                  @click="input_search2 = ''"
                  size="sm"
                  >Limpiar</b-button
                >
              </b-input-group-append>
            </b-input-group>
            <b-form-select
              :disabled="checkbox_2"
              :options="searchItems2"
              text-field="name"
              value-field="id"
              v-model="can_update"
              size="sm"
              :select-size="searchItems2.length < 7 ? searchItems2.length : 7"
              multiple
            ></b-form-select>
          </div>
          <!-- 3 -->
          <div class="ml-2 w-100">
            <h6 class="text-center">ELIMINAR</h6>
            <b-input-group class="mb-1">
              <b-input-group-append
                v-b-tooltip.v-secondary.auto.noninteractive="
                  `
                  ${!checkbox_3 ? 'Seleccionar Todos' : 'Deseleccionar Todos'}
                `
                "
              >
                <b-form-checkbox
                  class="mt-1"
                  id="checkbox-3"
                  v-model="checkbox_3"
                  @change="
                    () => {
                      if (checkbox_3) this.can_delete = Items.map((x) => x.id);
                      else this.can_delete = [];
                    }
                  "
                  name="checkbox-3"
                >
                </b-form-checkbox>
              </b-input-group-append>
              <b-form-input
                v-model="input_search3"
                type="search"
                size="sm"
                :disabled="checkbox_3"
                :placeholder="`Buscar ${$getVisibleNames(
                  Table.name,
                  false
                )}...`"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  :disabled="!input_search3"
                  @click="input_search3 = ''"
                  size="sm"
                  >Limpiar</b-button
                >
              </b-input-group-append>
            </b-input-group>
            <b-form-select
              :disabled="checkbox_3"
              :options="searchItems3"
              text-field="name"
              value-field="id"
              v-model="can_delete"
              size="sm"
              :select-size="searchItems3.length < 7 ? searchItems3.length : 7"
              multiple
            ></b-form-select>
          </div>
          <!-- acction -->
          <div class="ml-2 d-flex align-center">
            <b-button
              size="sm"
              variant="none"
              v-b-tooltip.v-secondary.auto.noninteractive="`Guardar Permisos`"
              @click="saveAccessTable"
            >
              <b-icon-box-arrow-in-down
                class="h2 mb-0"
                font-scale="1.7"
              ></b-icon-box-arrow-in-down>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast, generateUniqueId } from "@/utils/utils";
export default {
  name: "AccessCard",
  components: {},
  props: {
    Table: {
      type: Object,
      required: true,
    },
    Access: {
      type: Object,
      required: true,
    },
    Items: {
      type: Array,
    },
    TableAccess: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          table: null,
          access: null,
          can_create: false,
          can_read: "",
          can_update: "",
          can_delete: "",
          can_do_special_behavior: "",
        };
      },
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    show_can_read: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      table_access: { ...this.TableAccess },
      can_read: [],
      can_update: [],
      can_delete: [],
      checkbox_1: false,
      checkbox_2: false,
      checkbox_3: false,
      input_search1: "",
      input_search2: "",
      input_search3: "",
    };
  },
  computed: {
    searchItems1: function () {
      return this.Items.filter((x) => {
        return (
          this.$filtered(x.name, this.input_search1) ||
          this.can_read.includes(x.id)
        );
      }).sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    },
    searchItems2: function () {
      return this.Items.filter((x) => {
        return (
          this.$filtered(x.name, this.input_search2) ||
          this.can_update.includes(x.id)
        );
      }).sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    },
    searchItems3: function () {
      return this.Items.filter((x) => {
        return (
          this.$filtered(x.name, this.input_search3) ||
          this.can_delete.includes(x.id)
        );
      }).sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    },
  },
  methods: {
    saveAccessTable() {
      this.table_access.table = this.Table.id;
      this.table_access.access = this.Access.id;
      if (this.checkbox_1) this.table_access.can_read = "all";
      else {
        this.can_read = this.can_read.filter(Number);
        this.table_access.can_read = this.can_read.toString();
      }
      if (this.checkbox_2) this.table_access.can_update = "all";
      else {
        this.can_update = this.can_update.filter(Number);
        this.table_access.can_update = this.can_update.toString();
      }
      if (this.checkbox_3) this.table_access.can_delete = "all";
      else {
        this.can_delete = this.can_delete.filter(Number);
        this.table_access.can_delete = this.can_delete.toString();
      }
      if (!isNaN(this.Table.id) && !isNaN(this.Access.id)) {
        if (isNaN(this.table_access.id)) this.createTableAccess();
        else this.updateTableAccess();
      }
    },
    createTableAccess() {
      this.$restful
        .Post(`/teaching/table-access/`, this.table_access)
        .then((response) => {
          this.table_access = response;
          toast("Permiso actualizado");
        });
    },
    updateTableAccess() {
      this.$restful
        .Patch(
          `/teaching/table-access/${this.table_access.id}/`,
          this.table_access
        )
        .then((response) => {
          this.table_access = response;
          toast("Permiso actualizado");
        });
    },
  },
  watch: {
    can_read: function (n) {
      this.can_update = this.can_update.filter((x) => n.includes(x));
      this.can_delete = this.can_delete.filter((x) => n.includes(x));
    },
    can_update: function (n) {
      if (n.length > 0) {
        n.forEach((element) => {
          if (!this.can_read.includes(element)) this.can_read.push(element);
        });
      }
    },
    can_delete: function (n) {
      if (n.length > 0) {
        n.forEach((element) => {
          if (!this.can_read.includes(element)) this.can_read.push(element);
        });
      }
    },
    checkbox_1: function (n) {
      if (!n) {
        this.checkbox_2 = false;
        this.checkbox_3 = false;
      }
    },
    checkbox_2: function (n) {
      if (n) {
        this.checkbox_1 = true;
        this.can_read = this.Items.map((x) => x.id);
      }
    },
    checkbox_3: function (n) {
      if (n) {
        this.checkbox_1 = true;
        this.can_read = this.Items.map((x) => x.id);
      }
    },
  },
  mounted() {
    if (!isNaN(this.Table.id) && !isNaN(this.Access.id))
      this.$restful
        .Get(
          `/teaching/table-access/?table=${this.Table.id}&access=${this.Access.id}`
        )
        .then((response) => {
          if (response.length > 0) {
            this.table_access = response[0];
            if (response[0].can_read == "all") {
              this.checkbox_1 = true;
              this.can_read = this.Items.map((x) => x.id);
            } else this.can_read = response[0].can_read.split(",").map(Number);
            if (response[0].can_update == "all") {
              this.checkbox_2 = true;
              this.can_update = this.Items.map((x) => x.id);
            } else
              this.can_update = response[0].can_update.split(",").map(Number);
            if (response[0].can_delete == "all") {
              this.checkbox_3 = true;
              this.can_delete = this.Items.map((x) => x.id);
            } else
              this.can_delete = response[0].can_delete.split(",").map(Number);
          }
        });
  },
  created() {},
};
</script>

<style scoped>
.div-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>